<template>
  <div>
    <div :id="id"></div>
  </div>
</template>

<script>
import * as G2 from "@antv/g2";
export default {
  props: {
    columnData: Array,
     id: {
      //图表id
      type: String,
    },
  },
  name: "spectaculars",
  data() {
    return {
      chart: null,
    };
  },
  methods: {
    loadData() {
      const data = this.columnData
      // Step 1: 创建 Chart 对象
      const chart = new G2.Chart({
        container: this.id, // 指定图表容器 ID
        autoFit: true,
        height: 240,
        // 指定图表高度
      });
      // Step 2: 载入数据源
      
       chart.data(data);
       chart.scale('Val', {
          alias:'下辖司机数',//为value字段设置别名，在tooltip显示时则显示别名
          min: 0
      });
     chart.coord().transpose();
    chart.interval().position("Name*Val").color('#FF8B17').size(30)
      // Step 4: 渲染图表
      chart.render();
      this.chart = chart;
    },
  },
  watch: {
    columnData(val) {
      //数据更改重新渲染图表
      this.chart.changeData(val);
      this.chart.render();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadData();
    });
  },
};
</script>

<style scoped>
</style>