<template>
  <div class="GYBCmpt">
    <div>

      <div class="middle-box">
        <!--每日发牌统计-->
        <div class="total1">
          <div class="graphic" style="padding: 0;">
            <div style="display:flex; align-items:center;border-bottom: 1px solid #f5f5f5;">
              <div class="title">
                任务-每日发牌统计
              </div>
              <div style="margin-left: 12px; width: 130px;margin-bottom: 10px;">
                <el-select @change="changeCard" size="mini" v-model="cardId" placeholder="请选择">
                  <el-option v-for="item in taskList" :key="item.JobTaskId" :label="item.TaskName" :value="item.JobTaskId">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="info">
              <div class="items">
                <div class="name">任务:</div>
                <div class="value">{{taskDetail.TaskName}}</div>
              </div>
              <div class="items">
                <div class="name">车辆:</div>
                <div class="value">{{taskDetail.CarNum ? taskDetail.CarNum: 0}}辆</div>
              </div>
              <div class="items">
                <div class="name">计量员:</div>
                <div class="value">{{taskDetail.GaugerName}}</div>
              </div>
              <div class="items">
                <div class="name">司机:</div>
                <div class="value">{{taskDetail.DriverNum ?taskDetail.DriverNum: 0}}人</div>
              </div>
              <div class="items">
                <div class="name">地址:</div>
                <div class="value">{{taskDetail.TaskAddress}}</div>
              </div>
              <div class="items">
                <div class="name">单位:</div>
                <div class="value">{{taskDetail.SupplierName}}</div>
              </div>
            </div>
            <div class="info1">
              <div class="info1-box">
                <div class="items" style="color: #999999;">
                  <div class="name">单位:</div>
                  <div class="value">{{taskDetail.Unit}}</div>
                </div>
                <div class="items">
                  <div class="name">总发牌数量:</div>
                  <div class="value">{{total}}</div>
                </div>
              </div>
              <div class="time-box">
                <el-date-picker v-model="cardTime" type="month" @change="changeTime" placeholder="选择日期" size="mini" format="yyyy-MM" :clearable="false" value-format="yyyy-MM-dd">
                </el-date-picker>
              </div>
              <div style="width: 160px;"></div>
            </div>
            <div class="tu">
              <PillarChart class="chart" :id="'pillar1'" :cardData="cardData" />
            </div>
          </div>
        </div>

        <!--人力资源-->
        <div class="total2">
          <div class="graphic" style="padding: 0;">
            <div style="display:flex; align-items:center;border-bottom: 1px solid #f5f5f5;">
              <div class="title">
                任务-人力资源
              </div>
              <div style="margin-left: 12px; width: 130px;margin-bottom: 10px;">
                <el-select @change="changeCard2" size="mini" v-model="cardId2" placeholder="请选择">
                  <el-option v-for="item in taskList" :key="item.JobTaskId" :label="item.TaskName" :value="item.JobTaskId">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="info">
              <div class="items" style="width: 180px;">
                <div class="name">任务:</div>
                <div class="value" style="width: 8rem;overflow: hidden;
              text-overflow:ellipsis;">{{taskDetail2.TaskName}}</div>
              </div>
              <div class="items" style="width: 80px;">
                <div class="name">车辆:</div>
                <div class="value" style="white-space:nowrap;">{{taskDetail2.CarNum ? taskDetail2.CarNum: 0}}辆</div>
              </div>
              <div class="items">
                <div class="name">单位:</div>
                <div class="value">{{taskDetail2.SupplierName}}</div>
              </div>

            </div>
            <div class="info">
              <div class="items" style="width: 180px;">
                <div class="name">计量员:</div>
                <div class="value">{{taskDetail2.GaugerName}}</div>
              </div>
              <div class="items" style="width: 80px;">
                <div class="name">司机:</div>
                <div class="value" style="white-space:nowrap;">{{taskDetail2.DriverNum ?taskDetail2.DriverNum: 0}}人</div>
              </div>
              <div class="items">
                <div class="name">地址:</div>
                <div class="value" style="white-space:nowrap;">{{taskDetail2.TaskAddress}}</div>
              </div>

            </div>
            <div class="info1">
              <div class="info1-box">
                <div class="items" style="color: #999999;">
                  <div class="name">单位:</div>
                  <div class="value">人(下辖司机数)</div>
                </div>
              </div>
              <div style="width: 160px;"></div>
            </div>
            <div class="tu">
              <ColumnChart class="chart" :id="'column1'" :columnData="columnData" />
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="graphic">
          <div style="display:flex; align-items:center; padding-bottom: 8px;border-bottom: 1px solid #f5f5f5;
          padding-top: 1rem;">
            <div class="title">
              交易金额统计
            </div>
            <div class="invoice-tabs">
              <div :class="invoiceCode == item.Code ? 'isActive': ''" @click="selectInvoice(item.Code)" v-for="item in invoiceList" :key="item.Code">{{item.Name}}</div>
            </div>
          </div>
          <div class="invoice-tabs" style="margin-bottom: 8px;">
            <div style="margin: 8px 8px 0 0; font-weight: 400;" :class="sceneCode == item.Code ? 'isActive': ''" @click="selectScene(item.Code)" v-for="item in sceneList" :key="item.Code">{{item.Name}}</div>
          </div>
          <div class="time">
            <!-- <span @click="tabChange(item.id)" :class="timeActive == item.id ? 'active' : ''" v-for="item in timeList" :key="item.id">{{ item.name }}</span> -->
            <el-radio-group size="mini" v-model="radio" @change="tabChange">
              <el-radio-button v-for="item in timeList" :key="item.id" :label="item.id">{{ item.name }}</el-radio-button>

            </el-radio-group>
          </div>
          <div class="tu">
            <LineChart class="chart" :id="'line1'" :InvoiceData="InvoiceData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "../../../../components/chart/lineChart.vue";
import PillarChart from "../../../../components/chart/pillarChart.vue";
import ColumnChart from "../../../../components/chart/columnChart.vue";
import {
  getSceneType,
  getInvioceReport,
  getTaskList,
  getTaskDetail,
  getCardStatistic,
  getPeopleStatistic
} from '@/api/home/statistic'
import basicMixin from "@/mixins/basic";
export default {
  mixins: [basicMixin],
  data() {
    return {
      nowMonth: '', // 默认当前月份
      timeActive: 0, // 时间tab切换
      timeList: [
        {
          name: "过去一周",
          id: 0,
        },
        {
          name: "过去一个月",
          id: 1,
        },
        {
          name: "过去三个月",
          id: 2,
        },
      ],
      receiptActive: 1,
      InvoiceData: [], // 发票数据
      radio: 0, // 切换日期选项
      invoiceList: [], // 发票类型
      invoiceCode: '01', // 选中的发票类型
      sceneList: [],// 发票类型下的业务场景
      sceneCode: '',// 选中的场景类型
      cardId: '', // 选中的发牌任务
      cardId2: '', // 人力资源选中的任务
      cardTime: '', // 发牌日期
      taskList: [], // 发牌任务列表
      taskDetail: {}, // 任务详情
      taskDetail2: {}, // 人力资源任务详情
      cardData: [
        {
          num: 0,
          date: undefined,
          name: '总发牌数量'
        },
        {
          num: 0,
          date: undefined,
          name: '收牌数量'
        },
        {
          num: 0,
          date: undefined,
          name: '已付款'
        }
      ], // 发牌数据统计
      total: 0,// 总发牌数量
      columnData: [], // 人力资源条形图数据
    };
  },
  created() {
    //历史总数据
    this.getSceneType();
    this.getTaskList();
    this.getNowDate();
  },
  mounted() {
  },
  methods: {
    // 切换时间tab
    tabChange(id) {
      this.timeActive = id;
      this.getInvoiceData();
    },
    // 切换每日发牌时间
    changeTime(e) {
      this.cardTime = e
      if (!this.cardId) {
        this.$message.warning('请发牌选择任务')
        return
      }
      this.getCardStatistic()
    },
    // 切换每日发牌任务
    changeCard(e) {
      this.cardId = e
      this.getCardStatistic()
      this.getTaskDetail()
    },
    // 切换人力资源任务
    changeCard2(e) {
      this.cardId2 = e
      this.getPeopleStatistic()
      this.getTaskDetail2()
    },
    // 获取任务列表 默认选择第一个任务
    getTaskList() {
      getTaskList().then((res) => {
        this.taskList = res.data;

        this.cardId = res.data.length > 0 ? res.data[0].JobTaskId : ''
        this.cardId2 = res.data.length > 0 ? res.data[0].JobTaskId : ''
        if (this.cardId) {
          this.getTaskDetail()
          this.getCardStatistic()
        }
        if (this.cardId2) {
          this.getPeopleStatistic()
          this.getTaskDetail2()
        }
      });
    },
    // 获取任务详情
    getTaskDetail() {
      let params = {
        jobTaskId: this.cardId
      }
      getTaskDetail(params).then((res) => {
        this.taskDetail = res.data;
      });
    },
    // 获取人力资源任务详情
    getTaskDetail2() {
      let params = {
        jobTaskId: this.cardId2
      }
      getTaskDetail(params).then((res) => {
        this.taskDetail2 = res.data;
      });
    },
    // 获取人力资源统计数据
    getPeopleStatistic() {
      let params = {
        jobTaskId: this.cardId2
      }
      getPeopleStatistic(params).then((res) => {
        this.columnData = res.data;
      });
    },
    // 获取发牌统计
    getCardStatistic() {
      let params = {
        jobTaskId: this.cardId,
        dateTime: this.cardTime
      }
      getCardStatistic(params).then((res) => {
        this.total = 0
        if (res.data.MonthStatJobTimesModel.length > 0) {
          let arr1 = []  // 把获得的数据拆分成单个的数组项
          res.data.MonthStatJobTimesModel.forEach((element) => {
            let item1 = {
            num: 0,
            date: '',
            name: '',
          }
          let item2 = {
            num: 0,
            date: '',
            name: '',
          }
          let item3 = {
            num: 0,
            date: '',
            name: '',
          }
            item1.num = element.Counts
            item1.date = element.Date
            item1.name = '总发牌数量'
            arr1.push(item1)
            item2.num = element.ReceiveCounts
            item2.date = element.Date
            item2.name = '收牌数量'
            arr1.push(item2)
            item3.num = element.SettleCounts
            item3.date = element.Date
            item3.name = '已付款'
            arr1.push(item3)
          })
          this.cardData = arr1
          res.data.MonthStatJobTimesModel.forEach((item) => {
            this.total += item.Counts
          })
        } else {
          // 如果没有数据则给默认值重载数据
          this.cardData = [
            {
              num: 0,
              date: undefined,
              name: '总发牌数量'
            },
            {
              num: 0,
              date: undefined,
              name: '收牌数量'
            },
            {
              num: 0,
              date: undefined,
              name: '已付款'
            }
          ]
        }
      });
    },
    // 获取发票统计数据
    getInvoiceData() {
      let params = {
        dateType: this.timeActive,
        taskSceneType: this.sceneCode,
        sourceId: 2 // 工运宝
      };

      getInvioceReport(params).then((res) => {
        this.InvoiceData = res.data;
      });
    },
    // 获取当天日期
    getNowDate() {
      const myDate = new Date();
      let year = myDate.getFullYear();    //获取完整的年份(4位,1970-????)
      let month = (new Date().getMonth() + 1).toString().padStart(2, "0")
      let day = myDate.getDate().toString().padStart(2, "0")
      this.nowMonth = year + '-' + month + '-' + day
      this.cardTime = this.nowMonth
    },
    // 选择发票类型
    selectInvoice(code) {
      this.invoiceCode = code
      // 如果选择的发票类型和列表的相同 赋值场景类型
      this.invoiceList.forEach((item) => {
        if (code == item.Code) {
          this.sceneList = item.Childs
          this.sceneCode = item.Childs[0].Code
        }
      })
      this.getInvoiceData()
    },
    // 选择场景
    selectScene(code) {
      this.sceneCode = code
      this.getInvoiceData()
    },
    // 获取发票场景类型
    getSceneType() {
      getSceneType().then((res) => {
        this.invoiceList = res.data;
        this.invoiceCode = res.data[0].Code
        if (res.data[0].Childs) {
          this.sceneList = res.data[0].Childs
          this.sceneCode = res.data[0].Childs[0].Code
          this.getInvoiceData();
        }
      });
    },
  },
  components: {
    LineChart,
    PillarChart,
    ColumnChart
  },
};
</script>
<style lang="scss">
.GYBCmpt {
  .el-image {
    overflow: inherit !important;
  }
}
</style>
<style lang="scss" scoped>
@import '../../../../assets/style/variable.scss';
.GYBCmpt {
  .chart {
    width: 100%;
  }
  .pieChart {
    width: 200px;
  }
  .tu {
    display: flex;
    align-items: center;
  }
  .enter {
    transform: translateY(-6px);
    transition: all 0.3s;
    box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.16);
  }

  .middle-box {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    .pieData {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .total1 {
      position: relative;
      background: #ffffff;
      border-radius: 4px;
      padding: 0 10px;
      flex: 2;
      .info {
        display: flex;
        padding: 8px 0;
        border-bottom: 1px solid #f5f5f5;
        font-size: 14px;
        .items {
          display: flex;
          margin-right: 1rem;
          align-items: center;
          color: #333;
          .name {
            margin-right: 4px;
            white-space: nowrap;
          }
          .value {
            font-weight: 400;
            white-space: nowrap;
          }
        }
      }
      .info1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
        .info1-box {
          display: flex;
          align-items: center;
          .items {
            display: flex;
            align-items: center;
            margin-right: 16px;
            .name {
              margin-right: 4px;
              white-space: nowrap;
            }
            .value {
              font-weight: 400;
            }
          }
        }
        .time-box {
        }
      }
      .title {
        color: #333333;
        position: relative;
        text-indent: 10px;
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 8px;
        &::after {
          content: '';
          position: absolute;
          top: 36%;
          left: 0px;
          transform: translateY(-50%);
          width: 3px;
          height: 14px;
          border-radius: 5px;
          background: $primary;
        }
      }
    }
    .total2 {
      position: relative;
      background: #ffffff;
      border-radius: 4px;
      padding: 0 10px;
      flex: 1;
      margin-left: 16px;
      .info {
        display: flex;
        padding: 8px 0;
        border-bottom: 1px solid #f5f5f5;
        font-size: 14px;
        .items {
          display: flex;
          margin-right: 1rem;
          align-items: center;
          color: #333;
          .name {
            margin-right: 4px;
            white-space: nowrap;
          }
          .value {
            font-weight: 400;
            width: 10.5rem /* 200/16 */;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .info1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
        .info1-box {
          display: flex;
          align-items: center;
          .items {
            display: flex;
            align-items: center;
            margin-right: 16px;
            .name {
              margin-right: 4px;
              white-space: nowrap;
            }
            .value {
              font-weight: 400;
              white-space: nowrap;
            }
          }
        }
        .time-box {
        }
      }
      .title {
        color: #333333;
        position: relative;
        text-indent: 10px;
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 8px;
        &::after {
          content: '';
          position: absolute;
          top: 36%;
          left: 0px;
          transform: translateY(-50%);
          width: 3px;
          height: 14px;
          border-radius: 5px;
          background: $primary;
        }
      }
    }
    .tip {
      font-size: 16px !important;
      .car {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        color: #333;
        white-space: nowrap;
        font-size: 1rem;
      }
      .img {
        width: 1.75rem;
        height: 1.75rem /* 28/16 */;
        margin-right: 5px;
      }
    }
  }
  .graphic {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    border-radius: 4px;
    padding: 0 10px;
    position: relative;
    .invoice-tabs {
      display: flex;
      div {
        margin-left: 16px;
        cursor: pointer;
        padding-bottom: 4px;
        font-weight: bold;
      }
      .isActive {
        color: #409eff;
        border-bottom: 1px solid #409eff;
      }
    }

    .title {
      color: #333333;
      position: relative;
      text-indent: 10px;
      font-size: 14px;
      font-weight: bold;
      padding-bottom: 4px;
      &::after {
        content: '';
        position: absolute;
        top: 42%;
        left: 0px;
        transform: translateY(-50%);
        width: 3px;
        height: 14px;
        border-radius: 5px;
        background: $primary;
      }
    }
    .time {
      margin-bottom: 42px;
      display: flex;
      justify-content: center;
      font-size: 24px;
      font-weight: 400;
      .invoiceBtn {
        border-radius: 12px;
        // padding: 8px 20px;
        font-size: 24px;
        height: 50px;
        line-height: 50px;
        width: 176px;
        margin-bottom: 10px;
        cursor: pointer;
        margin-right: 56px;
      }
      span {
        display: inline-block;
        width: 98px;
        white-space: nowrap;
        border-radius: 6px;
        text-align: center;
        margin-right: 48px;
        font-size: 16px;
        background: #fff;
        height: 30px;
        cursor: pointer;
        line-height: 30px;
        color: #2081ff;
        border: 2px solid #2081ff;
      }
      .active {
        background: #2081ff;
        color: #fff;
      }
    }
  }
}
</style>