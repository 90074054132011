<template>
  <div>
    <div :id="id"></div>
  </div>
</template>

<script>
import * as G2 from "@antv/g2";

export default {
  props: {
    cardData: Array,
     id: {
      //图表id
      type: String,
    },
  },
  name: "spectaculars",
  data() {
    return {
      chart: null,
    };
  },
  methods: {
    loadData() {
      
      const data = this.cardData
      // Step 1: 创建 Chart 对象
      const chart = new G2.Chart({
        container: this.id, // 指定图表容器 ID
        autoFit: true,
        height: 240,
        // 指定图表高度
      });
      // Step 2: 载入数据源
      
      chart.data(data);

      chart.axis('name', {
    label: {
      textStyle: {
        fill: '#fff'
      }
    },
    tickLine: {
      alignWithLabel: false,
      length: 0,
      innerWidth: 10
    }
  });
   chart.legend(false)
      chart.interval().position("date*num").color('name',(name)=>{
        if(name == '总发牌数量'){
          return "#2081FF"
        } else if(name == '收牌数量') {
          return "#FF17D1"
        }else if(name == '已付款') {
          return "#00E855"
        }
      }).adjust([{
            type: 'dodge',
            marginRatio: 1 / 1888
        }]);
      // Step 4: 渲染图表
      chart.render();
      this.chart = chart;
    },
  },
  watch: {
    cardData(val) {
      //数据更改重新渲染图表
      this.chart.changeData(val);
      this.chart.render();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadData();
    });
  },
};
</script>

<style scoped>
</style>